import _ from 'lodash'
import moment from 'moment'
import React from 'react'
import useStyles from './account-order-details-style'
import { StateLabel } from '../../../../ui'

const AccountOrderDetailsView = ({ t, order }) => {
  const { details } = useStyles()
  return (
    <div className={details}>
      <p>
        <span>{t('screens.orderDetail.detailsOrder.order')}</span>
        <span>{_.get(order, 'referenceNumber')}</span>
      </p>
      <p>
        <span>{t('screens.orderDetail.detailsOrder.date')}</span>
        <span>{moment(_.get(order, 'createdAt', '')).format('YYYY-MM-DD')}</span>
      </p>
      <p>
        <span>{t('screens.orderDetail.detailsOrder.time')}</span>
        <span>{moment(_.get(order, 'createdAt', '')).format('HH:mm')}</span>
      </p>
      <p>
        <span>{t('screens.orderDetail.detailsOrder.store')}</span>
        <span>{_.get(order, 'shipments[0].pickupStore.name', '-')}</span>
      </p>
      <p>
        <span>{t('screens.orderDetail.detailsOrder.orderStatus')}</span>
        <StateLabel state={_.get(order, 'state')}>
          {
            t('screens.orderDetail.detailsOrder.state', {
              context: _.kebabCase(_.get(order, 'state')),
            })
          }
        </StateLabel>
      </p>
    </div>
  )
}

export default AccountOrderDetailsView
